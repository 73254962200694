import React from "react";
import { withPrefix } from "gatsby";
import { FontAwesomeIcon as FAI } from "@fortawesome/react-fontawesome";

import "../plugins/fontawesome";

import getPosition from "../lib/getPosition";

import { ClickItem } from "../modules/clickItem";
import { Layout } from "../modules/layout";
import { Button } from "../modules/button";
import { Popover } from "../modules/popover";
import * as Grid from "../modules/grid";
import * as Form from "../modules/form";

import { initialStore, useStoreController } from "./homeReducer";

const GOOGLE_MAP = "https://www.google.com.tw/maps";
const INFO = "info@ravelinfo.com";

const Home = (props) => {
  const { lang, metaData = {}, dictionary = {} } = props;
  const reducer = useStoreController(initialStore());

  const _redirectMove = (key) => {
    const position = getPosition(window.document.getElementById(`form_${key}`));
    window.scrollTo(0, position.y - 60);
  };
  const _clickMapMarker = () => {
    window.open(
      `${GOOGLE_MAP}/place/${dictionary.contact.information.location}`
    );
  };
  const _copyToClipboard = () => {
    const copyText = document.getElementById("info");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };

  const _updateForm = (event, key) => {
    const updateObj = {};
    updateObj[key] = {
      value: event.target.value,
      isError: false,
    };
    reducer.dispatch({ type: "update", obj: updateObj });
  };
  const _checkForm = () => {
    const CHECK_FORM = ["name", "email", "message"];
    const updateObj = {};
    const result = CHECK_FORM.find((key) => {
      if (!reducer.state[key].value.length) {
        updateObj[key] = {
          isError: true,
        };
        reducer.dispatch({ type: "update", obj: updateObj });
        _redirectMove(key);
        return true;
      }
      return false;
    });
    return !result;
  };

  const _submitMessage = () => {
    if (!_checkForm()) {
      return;
    }
    const url =
      process.env.NODE_ENV === "production"
        ? "https://ccd93fd337.execute-api.ap-southeast-1.amazonaws.com/prod/contact-ravel"
        : "https://osgl9feiw2.execute-api.ap-southeast-1.amazonaws.com/beta/contact-ravel-dev";
    const data = JSON.stringify({
      name: reducer.state.name.value,
      email: reducer.state.email.value,
      message: reducer.state.message.value,
    });
    fetch(url, {
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (res.ok) {
          const updateObj = {};
          updateObj.submitState = {
            value: "success",
          };
          reducer.dispatch({ type: "update", obj: updateObj });
        }
      })
      .catch(() => {
        const updateObj = {};
        updateObj.submitState = {
          value: "error",
        };
        reducer.dispatch({ type: "update", obj: updateObj });
      });
  };
  return (
    <Layout lang={lang} metaData={metaData} pagename={dictionary.pagename}>
      <div className="page page-home">
        <input id="info" className="home_hiddenInput" readOnly value={INFO} />

        <section id="top" className="home_section section-landing">
          <img
            className="landing_bg"
            src={`${withPrefix("/")}img/hero-img-radient-web.jpg`}
            alt="landing"
          />
          <Grid.Row
            className="section_content content-landing"
            align="middle"
            spacing={false}
          >
            <Grid.Col col={12} lg={6} className="text-center">
              <h1 className="landing_title">{dictionary.landing.title}</h1>
              <h2 className="landing_subtitle">
                {dictionary.landing.subtitle}
              </h2>
            </Grid.Col>
            <Grid.Col col={12} lg={6} className="text-center hidden show-lg">
              <img
                className="landing_logo"
                src={`${withPrefix("/")}img/ravel-logo.png`}
                alt="logo"
              />
              <h1 className="landing_name">RAVEL INFO</h1>
            </Grid.Col>
          </Grid.Row>
        </section>

        <section id="service" className="home_section section-service">
          <h3 className="section_title">
            <span>{dictionary.service.title}</span>
          </h3>
          <Grid.Row className="section_content" justify="center" align="top">
            <Grid.Col md={4} xl={3} className="service_item text-center">
              <img
                className="service_icon"
                src={`${withPrefix("/")}img/tv.svg`}
                alt="website_design"
              />
              <h4 className="service_title">
                {dictionary.service.features.website_design.title}
              </h4>
              <p className="service_description">
                {dictionary.service.features.website_design.description}
              </p>
            </Grid.Col>
            <Grid.Col md={4} xl={3} className="service_item text-center">
              <img
                className="service_icon"
                src={`${withPrefix("/")}img/work.svg`}
                alt="application_evelopment"
              />
              <h4 className="service_title">
                {dictionary.service.features.application_development.title}
              </h4>
              <p className="service_description">
                {
                  dictionary.service.features.application_development
                    .description
                }
              </p>
            </Grid.Col>
            <Grid.Col md={4} xl={3} className="service_item text-center">
              <img
                className="service_icon"
                src={`${withPrefix("/")}img/antenna.svg`}
                alt="customized_application"
              />
              <h4 className="service_title">
                {dictionary.service.features.customized_application.title}
              </h4>
              <p className="service_description">
                {dictionary.service.features.customized_application.description}
              </p>
            </Grid.Col>
          </Grid.Row>
        </section>

        <section id="about" className="home_section section-about">
          <h3 className="section_title">
            <span>{dictionary.about.title}</span>
          </h3>
          <Grid.Row className="section_content" justify="center" align="middle">
            <Grid.Col col={12} md={10} lg={6} className="text-center">
              {dictionary.about.descripiton.map((context) => {
                return (
                  <p className="about_description" key={context}>
                    {context}
                  </p>
                );
              })}
            </Grid.Col>
          </Grid.Row>
        </section>

        <section id="contact" className="home_section section-contact">
          <Grid.Row className="section_content" justify="center" align="middle">
            <Grid.Col md={9} className="text-center">
              <h3 className="section_title">
                <span>{dictionary.contact.title}</span>
              </h3>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="section_content" justify="center" align="top">
            <Grid.Col col={12} md={10} lg={6} colWrap className="content_form">
              <Grid.Row justify="center" align="middle">
                <Grid.Col
                  col={12}
                  md={6}
                  id="form_name"
                  className="contact_input"
                >
                  <Form.Label className="form_label" htmlFor="name">
                    {dictionary.contact.form.name}
                  </Form.Label>
                  <Popover
                    className="form_popup"
                    showState={reducer.state.name.isError}
                    position="bottom"
                    align="center"
                    trigger={
                      <Form.Input
                        id="name"
                        className="form_input"
                        type="text"
                        value={reducer.state.name.value}
                        onChange={(event) => {
                          _updateForm(event, "name");
                        }}
                      />
                    }
                  >
                    {dictionary.contact.error.name}
                  </Popover>
                </Grid.Col>
                <Grid.Col
                  col={12}
                  md={6}
                  id="form_email"
                  className="contact_input"
                >
                  <Form.Label className="form_label" htmlFor="email">
                    {dictionary.contact.form.email}
                  </Form.Label>
                  <Popover
                    className="form_popup"
                    showState={reducer.state.email.isError}
                    position="bottom"
                    align="center"
                    trigger={
                      <Form.Input
                        id="email"
                        type="text"
                        className="form_input"
                        value={reducer.state.email.value}
                        onChange={(event) => {
                          _updateForm(event, "email");
                        }}
                      />
                    }
                  >
                    {dictionary.contact.error.email}
                  </Popover>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify="center" align="middle" id="form_message">
                <Grid.Col col={12}>
                  <Form.Label className="form_label" htmlFor="message">
                    {dictionary.contact.form.message}
                  </Form.Label>
                </Grid.Col>
                <Grid.Col col={12} className="contact_input">
                  <Popover
                    className="form_popup"
                    showState={reducer.state.message.isError}
                    position="bottom"
                    align="center"
                    trigger={
                      <Form.Textarea
                        id="message"
                        className="form_input"
                        value={reducer.state.message.value}
                        onChange={(event) => {
                          _updateForm(event, "message");
                        }}
                        rows={7}
                      />
                    }
                  >
                    {dictionary.contact.error.message}
                  </Popover>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col col={12} className="text-right">
                  {(() => {
                    switch (reducer.state.submitState.value) {
                      case "success":
                        return (
                          <Button className="form_button" color="green">
                            {dictionary.contact.button.submitSuccess}
                          </Button>
                        );
                      case "error":
                        return (
                          <Button className="form_button" color="red">
                            {dictionary.contact.button.submitError}
                          </Button>
                        );
                      default:
                        return (
                          <Button
                            className="form_button"
                            color="yellow"
                            onClick={_submitMessage}
                          >
                            {dictionary.contact.button.submit}
                          </Button>
                        );
                    }
                  })()}
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col col={10} md={8} lg={3} spacing={false}>
              <Grid.Row>
                <Grid.Col colWrap col={12}>
                  <ClickItem
                    className="contact_method"
                    onClick={_clickMapMarker}
                  >
                    <FAI
                      className="method_icon"
                      icon={["fas", "map-marked-alt"]}
                      size="2x"
                      fixedWidth
                    />
                    <span className="method_description">
                      {dictionary.contact.information.address}
                      <Button type="link" color="yellow">
                        <FAI icon={["fas", "map-marker-alt"]} fixedWidth />
                      </Button>
                    </span>
                  </ClickItem>
                </Grid.Col>
                <Grid.Col col={12}>
                  <ClickItem
                    className="contact_method"
                    onClick={_copyToClipboard}
                  >
                    <FAI
                      className="method_icon"
                      icon={["fas", "envelope"]}
                      size="2x"
                      fixedWidth
                    />
                    <span className="method_description">
                      {INFO}
                      <Button type="link" color="yellow">
                        <FAI icon={["fas", "copy"]} fixedWidth />
                      </Button>
                    </span>
                  </ClickItem>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </section>
      </div>
    </Layout>
  );
};

export default Home;
