import React from "react";
import classnames from "classnames";

const FormInput = (props) => {
  const {
    className,
    id,
    type = "text",
    state,
    message,
    iconLeft,
    iconRight,
    ...inputProps
  } = props;
  const classList = [
    { "input-disabled": inputProps.disabled },
    state ? `input-state-${state}` : "",
  ];

  return (
    <div className={classnames("rvc-form_input", className, classList)}>
      <div className="input_container">
        {iconLeft ? (
          <div className="container_item item-icon icon-left">{iconLeft}</div>
        ) : (
          []
        )}
        <input
          id={id}
          className="container_item item-input"
          type={type}
          autoComplete="off"
          {...inputProps}
        />
        {iconRight ? (
          <div className="container_item item-icon icon-right">{iconRight}</div>
        ) : (
          []
        )}
      </div>
      {message ? <div className="input_message">{message}</div> : []}
    </div>
  );
};

export default FormInput;
