import { useReducer } from "react";

const initialStore = () => {
  return {
    submitState: { value: "none" },

    name: { value: "", isError: false },
    email: { value: "", isError: false },
    message: { value: "", isError: false },
  };
};

const useStoreController = (defaultForm) => {
  const reducer = (state, action) => {
    const newState = { ...state };
    const { obj = {} } = action;
    switch (action.type) {
      case "update":
        Object.keys(obj).forEach((entry) => {
          if (!newState[entry]) {
            newState[entry] = {};
          }
          Object.keys(obj[entry]).forEach((key) => {
            newState[entry][key] = obj[entry][key];
          });
        });
        return newState;
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, defaultForm);
  return {
    state,
    dispatch,
  };
};

export { initialStore, useStoreController };
