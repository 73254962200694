import React from "react";

import Home from "../../components/Home";

import metaData from "../../languages/en/metaData";
import dictionary from "../../languages/en/dictionary";

const PageHome = () => {
  return <Home lang="en" metaData={metaData} dictionary={dictionary} />;
};

export default PageHome;
