export default {
  pagename: "Home Page",
  landing: {
    title: "Accelerate your business growth",
    subtitle: "Ravel Info help you customize applications, just right fit",
  },
  service: {
    title: "Service",
    features: {
      website_design: {
        title: "Website Design",
        description:
          "Business website, Event website with Responsive design and server hosting",
      },
      application_development: {
        title: "Application Development",
        description:
          "Order Management, Warehouse Management, Time attendance System, etc., accelerate the company workflow and fit the existing ecosystem",
      },
      customized_application: {
        title: "Customized Application",
        description:
          "Interior system integration, API integration and implement your idea into reality",
      },
    },
  },
  about: {
    title: "About",
    descripiton: [
      "Ravel Info was founded in March 2019, consists of a group of passionate software engineers. We love and embrace new techs, and believe in constantly reform making extraordinary achievements.",
      "We are dedicated in web-based software development, helping traditional enterprises work in paperless office, and provide customized features more than package softwares and template websites.",
    ],
  },
  contact: {
    title: "Contact Ravel",
    form: {
      name: "Name",
      email: "Email",
      message: "Message",
    },
    error: {
      name: "Please enter your name.",
      email: "Please enter your Email.",
      message: "Please leave your messsage.",
    },
    button: {
      submit: "Contact Us",
      submitSuccess: "Success",
      submitError: "Error",
    },
    information: {
      address:
        "9F., No. 156, Sec. 3, Minsheng E. Rd., Songshan Dist.,Taipei City 105",
      location:
        "9F., No. 156, Section 3, Minsheng East Road, Songshan District",
      email: "info@ravelinfo.com",
    },
  },
};
