import React from "react";
import classnames from "classnames";

/**
 * The module for clickable element.
 *
 * props = {
 *  children,
 *  className,
 *  ...buttonProps
 * }
 *
 * @param {*} props
 */

const ClickItem = (props) => {
  const {
    children,
    className,
    onClick = () => {},
    onKeyDown = () => {},
    tabIndex = "0",
    ...clickItemProps
  } = props;
  return (
    <div
      role="button"
      className={classnames("rvc-click-item", className)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={tabIndex}
      {...clickItemProps}
    >
      {children}
    </div>
  );
};

export default ClickItem;
