import React from "react";
import classnames from "classnames";

const FormLabel = (props) => {
  const { children, className, ...labelProps } = props;

  return (
    <label className={classnames("rvc-form_label", className)} {...labelProps}>
      {children}
    </label>
  );
};

export default FormLabel;
