import React from "react";
import classnames from "classnames";

const FormTextarea = (props) => {
  const { children, id, className, type, ...textareaProps } = props;
  const classList = [{ "textarea-disabled": textareaProps.disabled }];

  return (
    <div className={classnames("rvc-form_textarea", className, classList)}>
      <textarea id={id} className="textarea_input" {...textareaProps} />
    </div>
  );
};
export default FormTextarea;
