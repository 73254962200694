export default {
  author: "Ravel Info Inc.",
  title: "Ravel Info",
  description:
    "Ravel Info was founded in March 2019. We are dedicated in rapid cross-platform software development, prove of concept and providing customized software and hardware integration service.",
  navbar: {
    service: "Service",
    about: "About",
    contact: "Contact",
    blog: "Blog",
    jobs: "Jobs",
  },
  copyright: "Copyright © 2019 Ravel Info Inc. All rights reserved.",
};
